import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { light, dark } from 'themes';
import { graphql } from 'gatsby';
import { Container } from 'react-grid-system';
import breakpoints from 'utils/breakpoints';
import Layout from 'components/Layout';
import Heading from 'components/Heading';
import Paragraph from 'components/Paragraph';
import CategorySwitcher from 'components/Blog/CategorySwitcher';
import BlogListItem from 'components/Blog/BlogListItem';

const Hero = styled.section`
  background: linear-gradient(180deg, #ddeeff 0%, #ffffff 100%);
  height: auto;
  display: flex;
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;

  @media screen and (min-width: ${breakpoints.md}) {
    align-items: center;
    padding-top: 135px;
    padding-bottom: 80px;
  }

  ${Paragraph} {
    max-width: 760px;

    @media screen and (min-width: ${breakpoints.md}) {
      margin-bottom: 40px;
    }
  }

  h3 {
    margin-top: 0;
  }
`;

const StyledCategorySwitcher = styled(CategorySwitcher)`
  margin: 0 auto;
`;

const BlogList = styled.section`
  padding-bottom: 200px;
`;

function BlogPage({ data }) {
  const categories = data.allCategoriesJson.nodes;
  const blogsRawData = data.allMarkdownRemark.nodes;
  const [activeCategoryKey, setActiveKey] = useState(categories[0].key);
  const visiblePosts = useMemo(() => {
    return blogsRawData.filter(
      rawPost =>
        rawPost.fields.category &&
        rawPost.fields.category.key === activeCategoryKey
    );
  }, [blogsRawData, activeCategoryKey]);

  return (
    <ThemeProvider theme={light}>
      <Layout fullSize pageTitle="News">
        <ThemeProvider theme={dark}>
          <Hero>
            <Container>
              <Heading title="Trusting Social News" />
              <Paragraph>
                Find out more about what is happening in our Trusting Social
                universe - we will keep you updated about relevant news and
                events here
              </Paragraph>
              {categories.length > 1 && (
                <StyledCategorySwitcher
                  onChange={setActiveKey}
                  items={categories}
                  activeKey={activeCategoryKey}
                />
              )}
            </Container>
          </Hero>
          <BlogList>
            {visiblePosts.map((blogRaw, i) => (
              <BlogListItem
                contentPosition={i % 2 === 0 ? 'right' : 'left'}
                key={blogRaw.id}
                title={blogRaw.frontmatter.title}
                category={blogRaw.fields.category.label}
                content={blogRaw.excerpt}
                featuredImage={blogRaw.frontmatter.featuredImage}
                slug={blogRaw.fields.slug}
              />
            ))}
          </BlogList>
        </ThemeProvider>
      </Layout>
    </ThemeProvider>
  );
}

BlogPage.propTypes = {
  data: PropTypes.shape({
    allCategoriesJson: PropTypes.shape({
      nodes: CategorySwitcher.propTypes.items,
    }),
    allMarkdownRemark: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
};

export const query = graphql`
  query blogCategories {
    allCategoriesJson {
      nodes {
        label
        key
      }
    }
    allMarkdownRemark(sort: { fields: frontmatter___time, order: DESC }) {
      nodes {
        id
        frontmatter {
          category
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
          }
          title
        }
        fields {
          slug
          category {
            label
            key
          }
        }
        excerpt(format: PLAIN, pruneLength: 200, truncate: true)
      }
    }
  }
`;

export default BlogPage;
